/**
 * Our global data/state-store
 *
 * @author David Faber
 * @copyright (c) Peritus Webdesign GmbH
 *
 * https://medium.com/@samuelresua/global-state-with-react-context-cfa99946900d
 */
import React from 'react';
import Globals from './Globals';

// Collecting the providers
const providers = [
];

// Store-Wrapper
const Store = ({children: initial}) => providers.reduce(
    (children, parent) => React.cloneElement(parent, {children}),
    initial
);

export {Store, Globals};
